import React, { Component } from "react"
import { Card, CardBody, CardTitle, Badge, Button, Container, Row, Col, Alert } from "reactstrap"
import MetaTags from 'react-meta-tags';
import PropTypes from 'prop-types'
import { connect } from "react-redux"
import "flatpickr/dist/themes/material_blue.css"
import logo  from "../../assets/images/site-logo.png"
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { withTranslation } from "react-i18next"
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import axios from "axios";
import {
  Label,
  Input,
  InputGroup,
  FormGroup,
  Nav,
  NavItem
} from "reactstrap";
class ProjectOpenHouseForm extends Component {
    constructor(props) {
        super(props)
        this.state = {
            projectData : ''
      
        }    
      }

      componentDidMount() {
        const { match: { params } } = this.props;
        axios.get('https://rscoreapi.azurewebsites.net/api/ProjectExt/guid', {
            params: {
                projectid: params.openhouseid
            }
          })
            .then(res => {
              const projectData = res.data;
              this.setState({ projectData });
            })
        
      }
      
     
    render() {
      const { projectData } = this.state;
      //console.log(projectData);
        return (
    <React.Fragment>
       <MetaTags>
            <title>Open House | Realty Space</title>
          </MetaTags>
      <ToastContainer autoClose={2000} />
      {this.state.projectData.isActive ? ( 
        <>
          <div className="headrtop">
                <div className="toppart">
                  <Row>
                    <Col sm="3">
                    <div className="d-flex flex-wrap gap-2">
                    <img src={logo}  alt="" height="70px"/>
                    </div>
                    </Col>
                    <Col xl="3">
                       
                    
                    </Col>

                    <Col sm="6">
                <Row>
                  <Col sm="4">
                    <h4 className="agentInfo"><strong>Agent</strong><br />
                    <span>{projectData?.agent?.firstName+" "+projectData?.agent?.lastName	}</span>
                    </h4>
                  </Col>
                  <Col sm="4">
                  <h4 className="agentInfo"><strong>Phone</strong><br />
                    <span>{projectData?.agent?.agentContact?.cContact?.phone }</span>
                    </h4>
                  </Col>
                  <Col sm="4">
                  <h4 className="agentInfo"><strong>Email</strong><br />
                    <span>{projectData?.agent?.primaryEmail	 }</span>
                    </h4>
                  </Col>
                </Row>


              </Col>
                  </Row>

                </div>

            </div> 
       <div className="page-content" style={{paddingTop:40}}>
         
          <Container fluid>
          {projectData && (
                <Row>
                  <Col xl="3"></Col>
                  <Col sm="6" className="text-center ">
                  <h2 style={{marginBottom:20}}>Open House</h2>
                   { this.state.projectData.projectLogoLink ? <img style={{width:200}} className="img-fluid" src={this.state.projectData.projectLogoLink	 } /> : '' }
                    <h2 style={{marginTop:20, marginBottom:20}}>{this.state.projectData.projectName	}</h2>
                    <h4 style={{marginBottom:20, marginTop:0, paddingTop:0}}>{this.state.projectData?.projectSiteAddress?.fullAddress	}</h4>
                  </Col>
                </Row>
              )}
          <Row>
                <Col xl="3"></Col>
              <Col xl="6">
              <Formik
                  enableReinitialize={true}
                  initialValues={{
                    projectCampaignId: 1100,
                    agentId:projectData.agentId,
                    campaignProjectId:projectData.id,
                    campaignProjectGuid:projectData.preConsProjectGuid,
                    firstName: (this.state && this.state.firstName) || "",
                    lastName:  (this.state && this.state.lastName) || "",
                    email: (this.state && this.state.email) || "",
                    leadSourceId:4,
                    mobileNo: (this.state && this.state.mobileNo) || "",
                    withAgent: '',
                    isActive:true
                  }}
                  validationSchema={Yup.object().shape({
                    firstName: Yup.string().required("This is Required"),
                    lastName: Yup.string().required("This is Required"),
                    email: Yup.string().required("This is Required"),
                    mobileNo: Yup.string()
                    .matches(/^[0-9]{10}$/, 'Mobile number must be exactly 10 digits')
                  })}

                  onSubmit={values => {
                    const updateFeature = {
                      projectCampaignId: values.projectCampaignId,
                      agentId: values.agentId,
                      campaignProjectId: values.campaignProjectId,
                      campaignProjectGuid:values.campaignProjectGuid,
                      firstName: values.firstName,
                      lastName: values.lastName,
                      email: values.email,
                      mobile:values.mobileNo,
                      leadSourceId:values.leadSourceId,
                      withAgent: values.withAgent,
                      isActive: values.isActive
                    };
                   
                    toast.loading("Please wait...");
                    axios.post('https://rscoreapi.azurewebsites.net/api/ProjectCampaignLead', updateFeature, {
                      headers: {
                        'Content-Type': 'application/json',
                        //'Authorization': str
                      }
                    })
                  .then(response => {
                    toast.dismiss();
                    
                    if(response.data.success){
                      toast.success("Openhouse Saved");
                    }else{
                      response.data.validationErrors.forEach(error => {
                        toast.error(error);
                      });
                    }
                    // Handle success
                  })
                  .catch(error => {
                    console.error(error);
                    // Handle error
                  });
                  }
                }

                >
                  {({ errors, touched, values, handleChange, setFieldValue }) => (
                    <Form
                      className="needs-validation"
                    >
                <Card className="newCard">
                <CardBody>
                <Row>
                        <Col sm="6">
                        <FormGroup className="mb-3">
                            <Label htmlFor="firstName">
                              {this.props.t("First Name*")}
                            </Label>
                           <Field
                              name="firstName"
                              type="text"
                              className={
                                "form-control" +
                                (errors.firstName && touched.firstName
                                  ? " is-invalid"
                                  : "")
                              }
                              id="name"
                            />
                             <ErrorMessage
                                  name="firstName"
                                  component="div"
                                  className="invalid-feedback"
                                />
                          </FormGroup>
                        </Col>
                        <Col sm="6">
                        <FormGroup className="mb-3">
                            <Label htmlFor="lastName">
                              {this.props.t("Last Name*")}
                            </Label>
                           <Field
                              name="lastName"
                              type="text"
                              className={
                                "form-control" +
                                (errors.lastName && touched.lastName
                                  ? " is-invalid"
                                  : "")
                              }
                              id="name"
                            />
                             <ErrorMessage
                                  name="lastName"
                                  component="div"
                                  className="invalid-feedback"
                                />
                          </FormGroup>
                        </Col>

                        
                        <Col sm="12">
                        <FormGroup className="mb-3">
                            <Label htmlFor="email">
                              {this.props.t("Email*")}
                            </Label>
                           <Field
                              name="email"
                              type="email"
                              className={
                                "form-control" +
                                (errors.email && touched.email
                                  ? " is-invalid"
                                  : "")
                              }
                              id="email"
                            />
                             <ErrorMessage
                                  name="email"
                                  component="div"
                                  className="invalid-feedback"
                                />
                          </FormGroup>
                        </Col>
                        <Col sm="12">
                        <FormGroup className="mb-3">
                            <Label htmlFor="mobileNo">
                              {this.props.t("Phone")}
                            </Label>
                           <Field
                              name="mobileNo"
                              type="text"
                              className={
                                "form-control" +
                                (errors.mobileNo && touched.mobileNo
                                  ? " is-invalid"
                                  : "")
                              }
                              id="mobileNo"
                            />
                             <ErrorMessage
                                  name="mobileNo"
                                  component="div"
                                  className="invalid-feedback"
                                />
                          </FormGroup>
                        </Col>
                        <Col md="12">
                                <FormGroup className="mb-3">
                                  <Label htmlFor="isClient">
                                    {this.props.t("Are you currently working with an Agent? ")}
                                  </Label>
                                  <div className="clearfix"></div>
                                  <div className="form-check form-check-inline">
                                    <Field
                                      type="radio"
                                      id="customRadioInline1"
                                      name="withAgent"
                                      className="form-check-input"
                                      value="Yes"
                                    />
                                    <label className="form-check-label mt-r" htmlFor="customRadioInline1">
                                    <span className="h4">Yes</span>
                                    </label>
                                  </div>
                                  &nbsp;
                                  <div className="form-check form-check-inline">
                                    <Field
                                      type="radio"
                                      id="customRadioInline2"
                                      name="withAgent"
                                      className="form-check-input"
                                      value="No"
                                    />
                                    <label className="form-check-label mt-r" htmlFor="customRadioInline2">
                                    <span className="h4">No</span>
                                    </label>
                                  </div>
                            
                                </FormGroup>
                              </Col>
                   
                              <Col sm="12">
                                <h6>By clicking submit below, you consent to allow us from time to time to contact you about our products and services you requested, as well as other content that may be of interest to you</h6>
                              </Col>
                        <Col sm="12">
                        <button type="submit" className="btn btn-add w-md  mt-27">Submit</button>  
                        </Col>
                            
                        </Row>

                </CardBody>
                </Card>

                </Form>
                  )}
              </Formik>
            </Col>

        </Row>

      </Container>
      </div>
      </>
      ):(
        <>
         <div className="page-content">
           <Container fluid>
            <Row>
                <Col sm="3"></Col>
                <Col sm="6">
                    <Alert color="warning" className="text-center"><h5>Sorry no project found</h5></Alert>
                </Col>
            </Row>
           </Container>
          </div>
        </>
      )}
    </React.Fragment>
  )
}
}
ProjectOpenHouseForm.propTypes = {
  t: PropTypes.any,
  ongetProjectUUIDAction: PropTypes.func,
  match: PropTypes.object,
  addProjectOpenHouse: PropTypes.func,
  projectData: PropTypes.object,


}

const mapStateToProps = ({ Project }) => (
  {
    projectData: Project.projectData,
    loading:Project.loading

  })
const mapDispatchToProps = dispatch => ({
  ongetProjectUUIDAction: (id) => dispatch(getProjectUUIDAction(id)),
  addProjectOpenHouse: (data) => dispatch(addProjectOpenHouse(data)),


});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ProjectOpenHouseForm));


