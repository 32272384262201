import React from "react"
import { Redirect } from "react-router-dom"



import Pages404 from "../pages/error/page-404";
import ProjectWorksheet from "../pages/Worksheets/index";
import WorksheetPreview from "../pages/Worksheets/preview"
import ProjectWorksheetThank from "../pages/Worksheets/thank";
import ProjectOpenHouseForm from "../pages/OpenHouse/index"
import ProjectInviteUser from "../pages/event/index"
import landingPage from "../pages/landing-page/index";
import ProjectDownload from "../pages/download/index"


const authProtectedRoutes = [
  
]

const publicRoutes = [
//{ path: "/thank-you/:worksheetId", component: ProjectWorksheetThank },
//{ path: "/preview/:worksheetId", component: WorksheetPreview },
 //{ path: "/:worksheetId", component: ProjectWorksheet },
//{ path: "/:projectId", component: landingPage },
//{ path: "/:openhouseid", component: ProjectOpenHouseForm },
{ path: "/:openhouseid", component: ProjectDownload },
 // { path: "/:projectEventInviteId", component: ProjectInviteUser },
  { path: "/", component: Pages404 }
]

export { authProtectedRoutes, publicRoutes }
